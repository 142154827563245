<template>
  <div class="register">

    <main>
      <section id="grocery_success">
        <div class="w1300">

          <div class="success_info txt-center" v-if="paymentSuccess === true">

            <!-- 選擇信用卡繳費時顯示 -->
            <p>
              <template v-if="$cookies.isKey('realname')">
                {{ $cookies.get('realname') }} 您好，<br><br>
              </template>
              <span class="txt-bold">您已報名成功，</span><br><br>
              請至「活動紀錄」確認您的報名資訊。
            </p>

            <!-- 選擇非信用卡繳費時顯示 -->
            <!-- <p>王小明 您好，<br><br>您的繳費資料如下，<br><br>請於繳費後返回我的課程查看您的購買課程。</p> -->

            <!-- 選擇ATM繳費時顯示下方付費資訊區塊 -->
            <!-- <div class="success_detail txt-left">
              <p class="success_detail_title h6 txt-bold">請透過ATM轉帳至以下銀行帳號以完成繳費</p>
              <div class="success_detail_content p">
                <ul class="success_detail_content_title">
                  <li>繳費銀行代碼</li>
                  <li>繳費帳號</li>
                  <li>付款金額</li>
                  <li>繳費期限</li>
                </ul>
                <ul class="success_detail_content_text">
                  <li>822</li>
                  <li>9103522175887271</li>
                  <li class="txt-bold"><span>2000</span>元</li>
                  <li class="txt-red txt-bold">2020/09/10 08:00</li>
                </ul>
              </div>
            </div> -->

            <!-- 選擇超商繳費時顯示下方付費資訊區塊 -->
            <!-- <div class="success_detail txt-left">
              <p class="success_detail_title h6 txt-bold">請至7-11/全家/萊爾富/OK超商 多媒體機台列印繳費單繳費</p>
              <div class="success_detail_content p">
                <ul class="success_detail_content_title">
                  <li>超商繳費代碼</li>
                  <li>付款金額</li>
                  <li>繳費期限</li>
                </ul>
                <ul class="success_detail_content_text">
                  <li>GW130412257496</li>
                  <li class="txt-bold"><span>2000</span>元</li>
                  <li class="txt-red txt-bold">2020/09/10 08:00</li>
                </ul>
              </div>
            </div> -->

            <router-link to="/register/record" class="btn">活動紀錄</router-link>
          </div>

          <div class="success_info txt-center" v-else-if="paymentSuccess === false">

            <p>
              <template v-if="$cookies.isKey('realname')">
                {{ $cookies.get('realname') }} 您好，<br><br>
              </template>
              <span class="txt-bold">由於繳費失敗，</span><br><br>
              請至「活動紀錄」重新繳費。
            </p>

            <router-link to="/register/record" class="btn">活動紀錄</router-link>
          </div>

          <div class="success_info txt-center" v-else>

            <p>
              查無訊息，請至「活動紀錄」確認繳費記錄。
            </p>

            <router-link to="/register/record" class="btn">活動紀錄</router-link>
          </div>

        </div>

      </section>
    </main>
  </div>
</template>

<script>
import { paymentInfo } from '@/lib/http';

export default {
  name: 'RegisterEventSuccess',
  data() {
    return {
      /**
       * Static Data
       */
      payment_uuid: null,
      /**
       * Axios Response Data
       */
      paymentSuccess: null,
    };
  },
  created() {
    if (this.$route.query && this.$route.query.uuid) {
      this.payment_uuid = this.$route.query.uuid;
    }

    if (this.payment_uuid) {
      paymentInfo({ uuid: this.payment_uuid }).then((result) => {
        // console.log('payment info', result);
        if (result.data.result.completed === true) {
          this.paymentSuccess = true;
        } else if (result.data.result.completed === false) {
          this.paymentSuccess = false;
        }
      });
    }

    this.$cookies.remove('eventApplyInfo');
  },
};
</script>
